/* WinnerModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .modal-container h1 {
    margin: 0 0 20px;
  }
  
  .modal-container button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .modal-container button:hover {
    background-color: #0056b3;
  }
  
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    font-size: 24px;
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .post {
    background-color: white;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .postHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .postHeader h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
  }
  
  .postHeader small {
    font-size: 12px;
    color: #666;
  }
  
  .content {
    font-size: 16px;
    color: #333;
    margin: 0;
  }
  
  .usernameLink {
    color: #007bff; /* Blue link color */
    text-decoration: none;
  }
  
  .usernameLink:hover {
    text-decoration: underline;
    color: #0056b3; /* Darker blue on hover */
  }
  
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .closeButton {
    position: relative;
    left: 160px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: black;
  }
  
  .input {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .submitButton, .toggleButton {
    padding: 10px 15px;
    margin: 10px 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submitButton {
    background-color: #28a745;
    color: white;
  }
  
  .submitButton:hover {
    background-color: #218838;
  }
  
  .toggleButton {
    background-color: #007bff;
    color: white;
  }
  
  .toggleButton:hover {
    background-color: #0056b3;
  }
  
  .modal h2 {
    color: black;
    font-size: 24px;
    margin-bottom: 15px;
  }
.square {
    width: 100%;
    height: 100px;
    font-size: 48px; /* Adjust font size to fit the button */
    border: 1px solid #000000; /* Consistent border */
    background-color: #f9f9f9; /* Optional: background color */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  
  .board {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px; /* Space between buttons */
  }
/* ConnectFour.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  h1 {
    margin-bottom: 20px;
  }
  
  
  .board {
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    gap: 5px;
    background-color: #0073e6;
    padding: 10px;
    border-radius: 10px;
    width: fit-content;
  }
  
  .row {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }
  
  .cell {
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  
  .red {
    background-color: red;
  }
  
  .yellow {
    background-color: yellow;
  }

  .hover {
    background-color: #b0b0ff;
  }
  